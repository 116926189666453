import { FunnelSteps } from './Funnel';

export const FeedbackFunnelSteps = [
  {
    name: 'selector',
    next: [
      { target: 'rate' }
    ]
  },
  {
    name: 'rate',
    next: [
      { target: 'feedback' }
    ]
  },
  {
    name: 'feedback',
    next: [
      { target: 'feedback_photo' }
    ]
  },
  {
    name: 'feedback_photo',
    next: [
      { target: 'contact' },
    ]
  },
  {
    name: 'contact',
    submit: true,
    next: [
      { target: 'success' },
    ]
  },
  {
    name: 'success',
    next: []
  }
] as FunnelSteps;
