import {
  Button,
  Flex,
  FormControl, FormErrorMessage, FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link as A, Spacer,
  Text
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import 'yup-phone-lite';
import { usePageData } from '../../../lib/hooks/PageDataContext';
import { useEffectOnceWhen, useThrottle } from 'rooks';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useFunnel } from '../../funnel/Funnel';
import { useI18n } from '../../../lib/hooks/I18n';
import AddressAutoComplete from '../controls/AddressAutoComplete';
import { useValidateEmail } from 'lib/hooks/UseValidateEmail';

const ContactDetailsStep = () => {
  const { t } = useI18n();
  const { query } = useRouter();
  const { stepValues, goForward, goBack, values, isSubmitting } = useFunnel();
  const { owner, settings, prefill } = usePageData();
  const { validateEmail, isLoading: isCheckingEmail } = useValidateEmail();

  const isLocationRequired = owner.settings['machine-location_field_active'] && !values.tag_id;
  const notificationChannel = settings.notification_customer_channels;

  const needsPhoneConfirmation = owner.settings.customers_confirmation_active && !prefill.phone_number && values.refund_requested;

  const schema = yup.object().shape({
    name: yup.string().nullable().required(t('common.validations.required')),
    email: yup.string().nullable().email(t('common.validations.email')).required(t('common.validations.required')),
    phone_number: yup.string().required(t('common.validations.required')).phone(owner.country_code, t('common.validations.phone')),
    location: yup.object()
      .nullable()
      .when('$requiredLocation', (required, schema) => required ? schema.required(t('common.validations.required')): schema)
  });

  const { register, setValue, setError, getValues, handleSubmit, formState: { errors, isSubmitted, isValid }} = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: stepValues({
      name: prefill.customer?.name ?? null,
      email: prefill.customer?.email ?? null,
      phone_number: prefill.phone_number || '',
      location: null,
    }),
    context: {
      requiredLocation: isLocationRequired
    },
  });

  useEffectOnceWhen(() => {
    setValue('name', `${values.refund_first_name} ${values.refund_last_name}`);
  }, !!(values.refund_first_name && values.refund_last_name && !getValues('name')));

  useEffectOnceWhen(() => {
    setValue('name', values.refund_name);
  }, !!(values.refund_name && !getValues('name')));

  useEffectOnceWhen(() => {
    setValue('email', values.refund_email);
  }, !!(values.refund_email && !getValues('email')));

  useEffectOnceWhen(() => {
    setValue('phone_number', values.refund_phone);
  }, !!(values.refund_phone && !getValues('phone_number')));

  const [onSubmit] = useThrottle(async (data: any) => {
    const isValid = await validateEmail(data.email);
    if (isValid) {
      goForward(data);
    } else {
      setError('email', {type: 'email', message: t('common.validations.email')});
    }
  }, 1000);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Heading fontSize="xl" mb="4">{ t('steps.contact.title') }</Heading>

      <FormControl id="name" isInvalid={!!errors.name}>
        <FormLabel>
          <Flex>{ t('fields.full_name.label') } <Spacer /><FormErrorMessage>{ errors.name?.message }</FormErrorMessage></Flex>
        </FormLabel>
        <Input
          type="text"
          maxLength={50}
          defaultValue={getValues('name')}
          {...register('name')}
        />
      </FormControl>

      <FormControl id="email" isInvalid={!!errors.email}>
        <FormLabel>
          <Flex>{ t('fields.email.label') } <Spacer /><FormErrorMessage>{ errors.email?.message }</FormErrorMessage></Flex>
        </FormLabel>
        <Input
          type="email"
          maxLength={50}
          defaultValue={getValues('email')}
          {...register('email')}
        />

        <FormHelperText>
          { notificationChannel.includes('email') && t('fields.email.aside') }
        </FormHelperText>
      </FormControl>

      <FormControl id="phone_number" isInvalid={!!errors.phone_number} isDisabled={!!prefill.phone_number}>
        <FormLabel>
          <Flex>{ t('fields.phone.label') } <Spacer /><FormErrorMessage>{ errors.phone_number?.message }</FormErrorMessage></Flex>
        </FormLabel>
        <Input
          type="tel"
          defaultValue={getValues('phone_number')}
          {...register('phone_number')}
        />

        <FormHelperText>
          { notificationChannel.includes('sms') && t('fields.phone.aside') } { needsPhoneConfirmation && t('fields.phone.confirmation_text') }
        </FormHelperText>
      </FormControl>

      <FormControl id="location" isInvalid={!!errors.location} hidden={!isLocationRequired} isDisabled={!isLocationRequired}>
        <FormLabel>
          <Flex>{ t('fields.machine_location.label') } <Spacer /><FormErrorMessage>{ errors.location?.message }</FormErrorMessage></Flex>
        </FormLabel>

        <AddressAutoComplete {...register('location')} country={owner.country_code} placeholder="" />
      </FormControl>

      <Flex justifyContent="space-between" mt="6" mb="10">
        <Button variant="ghost" size="lg" onClick={() => goBack(getValues())} disabled={isSubmitting || isCheckingEmail}>
          { t('common.buttons.back') }
        </Button>
        <Button
          size="lg"
          type="submit"
          isLoading={isSubmitting || isCheckingEmail}
          disabled={isSubmitting || isCheckingEmail || isSubmitted && !isValid}
        >
          { t('common.buttons.continue') }
        </Button>
      </Flex>

      <Text fontSize="xs">
        { t('steps.contact.privacy_text') } <Link href={`/${query.company_slug}/privacy`} passHref legacyBehavior><A isExternal>{ t('steps.contact.privacy_link_text') }</A></Link>.
      </Text>
    </form>
  );
};

export default ContactDetailsStep;
